import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import ManageMembers from './manageMembers/ManageMembers';
import AddMember from './AddMember';
import MembersOverview from './MembersOverview';
const StyledNavLink = styled(NavLink) `
  &&&&:hover {
   background-color: rgba(0,0,0,.03)
  }

  &&&&.active {
    background-color: rgba(0,0,0,.05);
  }
`;
const MembersPane = ({ isAdmin }) => {
    const { t } = useTranslation('common');
    const { path, url } = useRouteMatch();
    return React.createElement("div", null,
        React.createElement(Menu, { stackable: true },
            React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}`, exact: true }, t('groupsCommon.membersInfo')),
            isAdmin && React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/add` }, t('gatedCommunities.addMembers')),
            isAdmin && React.createElement(Menu.Item, { as: StyledNavLink, to: `${url}/overview` }, t('gcUserOverview.membersOverview'))),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: `${path}` },
                React.createElement(ManageMembers, { isAdmin: isAdmin })),
            isAdmin && React.createElement(Route, { exact: true, path: `${path}/add` },
                React.createElement(AddMember, null)),
            isAdmin && React.createElement(Route, { exact: true, path: `${path}/overview` },
                React.createElement(MembersOverview, null)),
            React.createElement(Redirect, { from: `${path}/*`, to: `${path}` })));
};
export default MembersPane;
