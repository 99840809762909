import { paginationParamsSerializer } from "api-operations/common";
import axios from "axios";
export async function getCommunityMembers(communityId, paginationFilterOptions) {
    const response = await axios.get(`/api/groups_management/v2/communities/${communityId}/members`, {
        params: { ...paginationFilterOptions },
        paramsSerializer: params => paginationParamsSerializer(params)
    });
    return response.data;
}
export async function getPendingCommunityMembers(communityId) {
    const response = await axios.get(`/api/groups_management/v2/communities/${communityId}/members/pending`);
    return response.data;
}
export async function addUserAllowedRoleInCommunity(communityId, memberId, role) {
    await axios.post(`/api/groups_management/v2/communities/${communityId}/members/${memberId}/addRole`, undefined, {
        params: { role }
    });
}
export async function deleteUserAllowedRoleInCommunity(communityId, memberId, role) {
    await axios.post(`/api/groups_management/v2/communities/${communityId}/members/${memberId}/deleteRole`, undefined, {
        params: { role }
    });
}
export async function setPriorityOfUserRoleInCommunity(communityId, memberId, role, priority) {
    await axios.put(`/api/groups_management/v2/communities/${communityId}/members/${memberId}/setPriority`, undefined, {
        params: { role, priority }
    });
}
export async function getMembersOverview(communityId, paginationFilterOptions) {
    const response = await axios.get(`/api/groups_management/v2/communities/${communityId}/members/overview`, {
        params: { ...paginationFilterOptions },
        paramsSerializer: params => paginationParamsSerializer(params)
    });
    return response.data;
}
export async function searchCandidatesToGc(communityId, name) {
    const response = await axios.get(`/api/groups_management/v2/communities/${communityId}/candidates/search`, {
        params: { name }
    });
    return response.data;
}
/////////////////////// V1 Community /////////////////////
export async function changeUserMembershipStatusInGatedCommunity(communityId, userId, status) {
    await axios.post(`/api/groups_management/communities/${communityId}/changeStatus`, undefined, {
        params: { user: userId, membershipStatus: status }
    });
}
export async function changeUserGcProfile(communityId, userId, profile) {
    await axios.put(`/api/groups_management/communities/${communityId}/changeProfile/${userId}/${profile}`);
}
export async function removeUserFromGatedCommunity(communityId, userId) {
    await axios.delete(`/api/groups_management/communities/${communityId}/removeUser/${userId}`);
}
export async function addUserToGatedCommunity(communityId, users) {
    await axios.post(`/api/groups_management/communities/${communityId}/addUsers`, users);
}
export async function getDefaultQaTable() {
    const response = await axios.get(`/api/workflow/errorClassification`);
    return response.data;
}
export async function getCommunityOverview(communityId) {
    const response = await axios.get(`/api/groups_management/communities/${communityId}/getCommunityOverview`);
    return response.data;
}
