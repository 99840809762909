import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import Pagination from "helpers/components/Pagination";
import { Styled } from 'StyledComponents';
import TablePlaceholder from 'helpers/components/TablePlaceholder';
import { StageAttachmentModal } from 'helpers/components/fileUpload/FileUploaderModal';
import { useRequesterJob } from './Dashboard';
import JobOverviewRow from './JobOverviewRow';
import CommentsModal from 'comments/CommentsModal';
const JobsOverview = ({ status }) => {
    const [reopenStageId, setReopenStageId] = useState(undefined);
    const [commentsModalOpen, setCommentsModalOpen] = useState(false);
    const [commentsModalJobId, setCommentsModalJobId] = useState(undefined);
    const { t } = useTranslation('common');
    const { allResultsCount, isError, isLoading, jobs, fetchJobs } = useRequesterJob();
    const orderOptions = [
        { key: 'DUD', text: t('pagination.filter.uploadDateD'), value: 'uploadDate DESCENDING' },
        { key: 'AUD', text: t('pagination.filter.uploadDateA'), value: 'uploadDate ASCENDING' },
        { key: 'FD', text: t('pagination.filter.fileNameD'), value: 'fileName DESCENDING' },
        { key: 'FA', text: t('pagination.filter.fileNameA'), value: 'fileName ASCENDING' },
        { key: 'SD', text: t('pagination.filter.statusD'), value: 'status DESCENDING' },
        { key: 'SA', text: t('pagination.filter.statusA'), value: 'status ASCENDING' },
        { key: 'LFD', text: t('pagination.filter.langFromD'), value: 'langFrom DESCENDING' },
        { key: 'LFA', text: t('pagination.filter.langFromA'), value: 'langFrom ASCENDING' },
        { key: 'TFD', text: t('pagination.filter.langToD'), value: 'langTo DESCENDING' },
        { key: 'TFA', text: t('pagination.filter.langToA'), value: 'langTo ASCENDING' }
    ];
    const openCommentsModal = (jobId) => {
        setCommentsModalJobId(jobId);
        setCommentsModalOpen(true);
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Styled.H3, { "data-testid": "overview--header" }, status == 'DELIVERED' ? t('dashboard.finishedJobs') : t('dashboard.jobsInProgress')),
        React.createElement(Pagination, { orderOptions: orderOptions, totalResults: allResultsCount, langs: true, fetchData: fetchJobs, tableIdentifier: status == 'DELIVERED' ? 'requesterJobsFinished' : 'requesterJobsOngoing', interval: 20000 },
            React.createElement(Table, { "data-testid": "overview-table", celled: true, selectable: true, striped: true, color: status == 'DELIVERED' ? 'green' : 'orange' },
                React.createElement(Table.Header, { "data-testid": "overview-table--header" },
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, null),
                        React.createElement(Table.HeaderCell, null, t('dashboard.externalProjectId')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.projectName')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.languages')),
                        status == 'IN_PROGRESS' && React.createElement(Table.HeaderCell, null, t('dashboard.dueDate')),
                        status == 'IN_PROGRESS' && React.createElement(Table.HeaderCell, null, t('dashboard.stageDeadline')),
                        status == 'DELIVERED' && React.createElement(Table.HeaderCell, null, t('dashboard.deliveryDate')),
                        status == 'IN_PROGRESS' && React.createElement(Table.HeaderCell, null, t('dashboard.status')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.totalCost')),
                        React.createElement(Table.HeaderCell, null, t('dashboard.actions')))),
                isLoading || isError || !jobs.length ? React.createElement(TablePlaceholder, { isLoading: isLoading, isError: isError, noEntries: !jobs.length })
                    : jobs.map(job => React.createElement(JobOverviewRow, { job: job, key: job.id, status: status, reopenStageId: reopenStageId, setReopenStageId: setReopenStageId, openCommentsModal: openCommentsModal })))),
        React.createElement(CommentsModal, { isOpen: commentsModalOpen, setIsOpen: setCommentsModalOpen, jobId: commentsModalJobId }),
        reopenStageId && React.createElement(StageAttachmentModal, { open: !!reopenStageId, setOpen: () => setReopenStageId(undefined), stageAttachments: [], stageId: reopenStageId }));
};
export default React.memo(JobsOverview);
