import React from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import AssetElement from './AssetElement';
import Header from './components/header';
import SelectionTools from './SelectionTools';
import StatusBar from './StatusBar';
import SectionBar from './components/sectionBar/index';
import { ancestorOfType, calculateProgress, chooseActiveSection, consumeEvent, firstSelectionRange, getSanitizedHTML, isAlphanumeric, isScriptoContinua } from './utils';
import { getAssignmentInfo, commitAsset, refreshAssignmentProgress, getAssignmentJobSectionAssets, getJobSectionNextUncompletedAssetOrder, getAssetsTerminology } from 'api-operations/editor/editor';
import ErrorModal from 'errors/components/ErrorModal';
import './styles.scss';
import { HTTPLogger } from 'logger/HTTPLoggerStatic';
import ContentEditionModule from './modules/contentEdition/ContentEditionModule';
import PendingAssignmentModule from './modules/pendingAssignment/PendingAssignmentModule';
import UploadDocumentModule from './modules/uploadDocument/UploadDocumentModule';
import SearchModule from './modules/search/SearchModule';
import TerminologyModule from './modules/terminology/TerminologyModule';
import TermCreationModule from './modules/termCreation/TermCreationModule';
import TokenEditionModule from './modules/tokenEdition/TokenEditionModule';
import HelpModule from './modules/help/HelpModule';
import AssetErrorModule from './modules/assetError/AssetErrorModule';
import CommentsModule from './modules/comments/CommentsModule';
import AttachmentsModule from './modules/attachments/AttachmentsModule';
import TokenRemovalModule from './modules/tokenRemoval/TokenRemovalModal';
import DeliverAssetsModule from './modules/deliverAssets/DeliverAssetsModule';
import SnapshotModule from './modules/snapshots/SnapshotsModule';
import SourceFilePreviewModule from './modules/sourceFilePreview/SourceFilePreviewModule';
import SourceFileDownloadModule from './modules/sourceFileDownload/SourceFileDownloadModule';
import InfiniteScrollLoader from './components/infiniteScrollLoader';
import { ProjectDetails } from './components/header/ProjectDetails';
import { DueDetails } from './components/header/DueDetails';
import { ExfluencyLoader } from './components/loader';
import { InfiniteScroll } from './InfiniteScroll';
import { AssetTooltip } from './AssetTooltip';
import SubAssetElement from './SubAssetElement';
import i18next from 'i18next';
import _ from 'lodash';
import { EditorTooltip } from './Tooltip';
import PermissionErrorModal from 'errors/components/PermissionErrorModal';
import UserService from 'UserService';
import SectionHeader from './components/sectionHeader';
import UploadAfterDocumentModal from './modules/uploadDocument/UploadAfterDocumentModal';
import AssetMultipleOccurrencesModal from './components/AssetMultipleOccurrencesModal';
import FormattingModule from './modules/formatting/FormattingModule';
import UniversalTagElement from './UniversalTag';
import AssetMissingFormattingModal from './components/AssetMissingFormattingModal';
import AssetSourceWindow from './components/assetSourceWindow/AssetSourceWindow';
import AssetRestoreModal from './components/AssetRestoreModal';
import { toast } from 'react-toastify';
import TerminologyElement from './TerminologyElement';
import { Popup } from './components/tooltip/Tooltip';
const MIN_TERM_LENGTH = 2;
const MIN_FORMATTING_LENGTH = 1;
const MAX_TERM_LENGTH = 255;
const ASSET_BATCH_SIZE = 20;
const MAX_DISPLAYED_ASSETS = 100;
const COMPLETE_BUTTON_THROTTLE = 500;
const RIGHT_TO_LEFT_LANGUAGES = ['ar', 'he'];
export class EditorNextGen extends React.Component {
    constructor(props) {
        super(props);
        this.domNode = null;
        this.modules = [];
        this.scroll = null;
        this.tooltip = new AssetTooltip();
        this.simpleTooltip = new Popup();
        this.leftSidePanel = document.createElement("div");
        this.content = document.createElement("div");
        this.rightSidePanel = document.createElement("div");
        this.disabledScreen = document.createElement("div");
        this.universalTagStyle = document.createElement('style');
        this.stageId = '';
        this.isAssignmentPending = false;
        this.additionalPanel = false;
        this.selectionRange = null;
        this.taxonomy = [];
        this.sourceLanguageTextDirection = 'ltr';
        this.targetLanguageTextDirection = 'ltr';
        this.hasSourceFile = false;
        this.requiresAssetCompletion = true;
        this.attachments = [];
        this.commitTimeouts = new Map();
        this.isAssignmentProgressRefreshed = false;
        this.editable = false;
        this.sections = [];
        this._activeSection = null;
        this.sourceLanguage = '';
        this.targetLanguage = '';
        this.executorId = '';
        this.status = '';
        this.observer = new MutationObserver(_.throttle(() => {
            this.activeAsset && this.onAssetChange(this.activeAsset);
        }, 1000));
        this.state = { error: null };
        this.assignmentId = props.assignmentId;
        this.header = new Header(this);
        this.sectionHeader = new SectionHeader(this);
        this.statusBar = new StatusBar();
        this.selectionTools = new SelectionTools();
        this.sectionBar = new SectionBar();
        this.selectionChangeListener = this.onSelectionChange.bind(this);
        document.addEventListener('selectionchange', this.selectionChangeListener);
    }
    async componentDidMount() {
        var _a;
        this.domNode = ReactDOM.findDOMNode(this);
        const exflLoader = new ExfluencyLoader();
        this.domNode.appendChild(exflLoader);
        this.createStructure();
        const assignment = await this.fetchAssignment();
        if (!assignment) {
            return; // error is set anyway in fetchAssignment()
        }
        this.domNode = ReactDOM.findDOMNode(this);
        this.sections = assignment.sections;
        this.stage = assignment.stage;
        this.stageId = assignment.stageId;
        this.taxonomy = assignment.taxonomy;
        this.isAssignmentPending = assignment.status == 'PENDING';
        this.statusBar.totalAssets = assignment.assetCount;
        this.requiresAssetCompletion = assignment.requiresAssetCompletion;
        this.attachments = assignment.attachments;
        this.editable = assignment.editable;
        this.sourceLanguage = assignment.jobSourceLanguage;
        this.targetLanguage = assignment.jobTargetLanguage;
        this.executorId = assignment.executorId;
        this.status = assignment.status;
        try {
            refreshAssignmentProgress(this.assignmentId);
        }
        catch (erorr) {
            HTTPLogger.warn("Editor - could refresh assignment progress");
        }
        this.header.detailsSection.appendChild(new ProjectDetails(assignment.name, i18next.t(`languages.${assignment.jobSourceLanguage}`) + ' -> ' + i18next.t(`languages.${assignment.jobTargetLanguage}`)));
        this.header.detailsSection.appendChild(new DueDetails(assignment.dueDate));
        this.content.lang = this.targetLanguage.split("_")[0] || "";
        RIGHT_TO_LEFT_LANGUAGES.find(lang => (assignment.sourceLanguage.split("_")[0] || "") == lang) && (this.sourceLanguageTextDirection = 'rtl');
        RIGHT_TO_LEFT_LANGUAGES.find(lang => (assignment.targetLanguage.split("_")[0] || "") == lang) && (this.targetLanguageTextDirection = 'rtl');
        this.loadModules();
        this.section = chooseActiveSection(assignment.sections);
        this.sectionBar.sequentiality = this.isRedactionOrFinalRedactionStage && assignment.editable;
        this.sectionBar.sections = this.sections;
        this.sectionBar.active = ((_a = chooseActiveSection(assignment.sections)) === null || _a === void 0 ? void 0 : _a.id) || null;
        this.sectionBar.onChange = (sectionId) => {
            var _a;
            if (sectionId === ((_a = this.section) === null || _a === void 0 ? void 0 : _a.id))
                return;
            this.section = this.sections.find(section => section.id === sectionId) || null;
            this.sectionBar.active = sectionId;
        };
        if (!this.section)
            return;
        this.createLoaders();
        this.finishLoading();
    }
    createStructure() {
        this.domNode.addEventListener("contextmenu", consumeEvent);
        this.domNode.contentEditable = "false";
        const mainContainer = document.createElement("main");
        this.leftSidePanel.tabIndex = -1;
        this.leftSidePanel.className = "sidePanel left";
        this.content.tabIndex = -1;
        this.content.contentEditable = "false";
        this.content.className = "editorContent";
        this.rightSidePanel.tabIndex = -1;
        this.rightSidePanel.className = "sidePanel right";
        this.disabledScreen.className = "disabled";
        this.content.addEventListener("keydown", this.onKeyDown.bind(this));
        this.content.addEventListener("keydown", _.throttle(this.onKeyDownComplete.bind(this), COMPLETE_BUTTON_THROTTLE));
        this.content.addEventListener("keypress", this.onKeyPress.bind(this));
        this.content.addEventListener("mouseup", this.onMouseUp.bind(this));
        this.content.addEventListener("input", this.onInput.bind(this));
        this.content.addEventListener("cut", this.onClipboardEvent.bind(this));
        this.content.addEventListener("paste", this.onClipboardEvent.bind(this));
        this.content.addEventListener("copy", this.onClipboardEvent.bind(this));
        this.content.addEventListener("dragover", consumeEvent);
        this.content.addEventListener("drop", consumeEvent);
        this.setFormattingSelection();
        this.domNode.appendChild(this.header);
        this.domNode.appendChild(this.sectionBar);
        this.domNode.appendChild(mainContainer);
        this.domNode.appendChild(new EditorTooltip());
        mainContainer.appendChild(this.tooltip);
        mainContainer.appendChild(this.simpleTooltip);
        mainContainer.appendChild(this.leftSidePanel);
        mainContainer.appendChild(this.content);
        mainContainer.appendChild(this.rightSidePanel);
        mainContainer.appendChild(this.universalTagStyle);
        this.domNode.appendChild(this.statusBar);
        this.domNode.appendChild(this.selectionTools);
        this.domNode.appendChild(this.disabledScreen);
    }
    componentWillUnmount() {
        var _a;
        this.modules.forEach(m => m.unload());
        this.content.remove();
        this.domNode.remove();
        this.universalTagStyle.remove();
        document.removeEventListener('selectionchange', this.selectionChangeListener);
        (_a = document.querySelector('xfl-new-editor-asset-source-window')) === null || _a === void 0 ? void 0 : _a.remove();
    }
    loadModule(module) {
        if (this.modules.some(m => m.moduleId == module.moduleId)) {
            HTTPLogger.warn(`System – module already loaded: ${module.moduleId}`);
            return;
        }
        this.modules.push(module);
        HTTPLogger.info(`System – loaded module: ${module.moduleId}`);
    }
    unloadModule(moduleId) {
        var _a;
        (_a = this.modules.find(m => m.moduleId == moduleId)) === null || _a === void 0 ? void 0 : _a.unload();
        this.modules = this.modules.filter(m => m.moduleId != moduleId);
    }
    loadModules() {
        var _a, _b, _c, _d;
        const loadTokenEditionModule = () => {
            this.tokenEditionModule = new TokenEditionModule(this);
            this.loadModule(this.tokenEditionModule);
        };
        const loadFormattingModule = () => {
            this.formattingModule = new FormattingModule(this);
            this.loadModule(this.formattingModule);
        };
        const loadContentEditionModule = () => {
            this.contentEditionModule = new ContentEditionModule(this);
            this.loadModule(this.contentEditionModule);
        };
        this.loadModule(new SearchModule(this));
        this.editable && this.isRedactionStage && loadTokenEditionModule();
        this.isEnhancementOrTrustMiningOrCorrectionStage && this.loadModule(new TerminologyModule(this));
        this.editable && loadFormattingModule();
        this.loadModule(new CommentsModule(this));
        this.attachments.length > 0 && this.loadModule(new AttachmentsModule(this));
        this.loadModule(new HelpModule(this));
        this.editable && this.isFinalRedactionStage && this.loadModule(new SnapshotModule(this));
        this.editable && this.isRedactionOrFinalRedactionStage && this.loadModule(new UploadDocumentModule(this));
        this.editable && loadContentEditionModule();
        this.editable && this.loadModule(new DeliverAssetsModule(this));
        this.editable && this.isRedactionOrFinalRedactionStage && this.loadModule(new TokenRemovalModule(this));
        this.editable && this.isEnhancementOrTrustMiningOrCorrectionStage && this.loadModule(new TermCreationModule(this));
        this.isTrustMiningStage && this.loadModule(new AssetErrorModule(this));
        this.isAssigmentPending && this.loadModule(new PendingAssignmentModule(this));
        this.isRedactionOrFinalRedactionStage && this.domNode.insertBefore(this.sectionHeader, this.sectionBar.nextSibling);
        if (!this.isRedactionStage) {
            this.assetMultipleOccurrencesModal = new AssetMultipleOccurrencesModal();
            (_a = this.domNode) === null || _a === void 0 ? void 0 : _a.appendChild(this.assetMultipleOccurrencesModal);
            this.assetSourceWindow = new AssetSourceWindow(this.sourceLanguageTextDirection, this.sourceLanguage.replace('_', '-'), this);
            (_b = this.domNode) === null || _b === void 0 ? void 0 : _b.appendChild(this.assetSourceWindow);
        }
        this.assetMissingFormattingModal = new AssetMissingFormattingModal();
        (_c = this.domNode) === null || _c === void 0 ? void 0 : _c.appendChild(this.assetMissingFormattingModal);
        this.assetRestoreModal = new AssetRestoreModal();
        (_d = this.domNode) === null || _d === void 0 ? void 0 : _d.appendChild(this.assetRestoreModal);
    }
    get isRedactionOrFinalRedactionStage() {
        return ['REDACTION', 'FINAL_REDACTION'].includes(this.stage);
    }
    get isEnhancementOrTrustMiningOrCorrectionStage() {
        return ['ENHANCEMENT', 'TRUST_MINING', 'CORRECTION'].includes(this.stage);
    }
    get isFinalRedactionStage() {
        return this.stage == 'FINAL_REDACTION';
    }
    get isTrustMiningStage() {
        return this.stage == 'TRUST_MINING';
    }
    get isRedactionStage() {
        return this.stage == 'REDACTION';
    }
    get isAssigmentPending() {
        return !this.editable && this.status == "PENDING" && UserService.getTokenUserInfo().id === this.executorId;
    }
    setFormattingSelection(value) {
        if (!value) {
            this.universalTagStyle.textContent = ``;
        }
        else {
            const [assetId, tagId] = value.uniqueId;
            this.universalTagStyle.textContent = `
      xfl-new-editor-asset[assetid="${assetId}"] universal-tag[id="${tagId}"] {
          box-shadow: inset 0 -5px #89A0EF;
        }
      `;
        }
    }
    finishLoading() {
        var _a, _b;
        this.placeCursorInActiveAsset();
        (_b = (_a = this.domNode) === null || _a === void 0 ? void 0 : _a.querySelector("xfl-new-editor-exfluency-loader")) === null || _b === void 0 ? void 0 : _b.remove();
        this.modules.forEach(plugin => plugin.finishLoading());
    }
    async setSectionScroll(sectionId, assetCount) {
        if (!sectionId)
            return;
        if (this.scroll)
            this.scroll.disabledScroll = true;
        this.removeAllAssets();
        let nextAssetOrder;
        try {
            nextAssetOrder = await getJobSectionNextUncompletedAssetOrder(this.assignmentId, sectionId);
        }
        catch (error) {
            nextAssetOrder = 0;
        }
        this.scroll = new InfiniteScroll(this.content, sectionId, assetCount, nextAssetOrder == -1 ? 0 : nextAssetOrder, ASSET_BATCH_SIZE, MAX_DISPLAYED_ASSETS, this.fetchAssets.bind(this), this.removeAssets.bind(this), this.updateLoaderState.bind(this));
        this.scroll.subscribe({
            next: ({ assets, sectionId }) => {
                var _a, _b, _c, _d, _e;
                if (sectionId !== ((_a = this.section) === null || _a === void 0 ? void 0 : _a.id))
                    return;
                assets.forEach((asset) => {
                    this.insertAsset(asset, asset.order);
                });
                this.fetchAssetsTerminology([...new Set(assets.map(a => a.id))]);
                if (this.disabled) {
                    this.jumpToNextUnfinishedAsset();
                }
                this.disabled = false;
                if ((_b = this.scroll) === null || _b === void 0 ? void 0 : _b.disabledScroll) {
                    this.jumpToNextUnfinishedAsset();
                    this.scroll.disabled = false;
                }
                HTTPLogger.info(`Editor loaded assets: ${(_c = assets[0]) === null || _c === void 0 ? void 0 : _c.order}-${(_d = assets[assets.length - 1]) === null || _d === void 0 ? void 0 : _d.order}`);
                (_e = this.scroll) === null || _e === void 0 ? void 0 : _e.loadingFinishedSubject.next();
            },
            error: (error) => HTTPLogger.error('System - Failed to get assets', error),
            complete: () => { }
        });
    }
    createLoaders() {
        this.createLoader('forward');
        this.createLoader('backward');
    }
    createLoader(direction) {
        const loader = new InfiniteScrollLoader(direction);
        if (direction === 'backward') {
            this.content.prepend(loader);
        }
        else {
            this.content.append(loader);
        }
    }
    updateLoaderState(forward, hidden, callback) {
        const loader = this.content.querySelector(`xfl-new-editor-infinite-scroll-loader.${forward ? 'forward' : 'backward'}`);
        if (!loader)
            return;
        loader.callback = callback;
        loader.hidden = hidden;
    }
    removeAllAssets() {
        this.disabled = true;
        const assets = this.content.querySelectorAll("xfl-new-editor-asset");
        if (assets.length == 0)
            return;
        for (let index = 0; index < assets.length; index++) {
            assets[index].remove();
        }
    }
    removeAssets(from, to) {
        const assets = Array.from(this.content.querySelectorAll("xfl-new-editor-asset"));
        assets.forEach((asset) => {
            if (asset.order >= from && asset.order <= to)
                asset === null || asset === void 0 ? void 0 : asset.remove();
        });
    }
    insertAsset(asset, index) {
        var _a;
        const element = new AssetElement(asset.id, index, asset.translationLevel, asset.completed || false, asset.recycled, asset.modified, asset.format, this);
        element.currentText = asset.currentText;
        element.subAssets = asset.subAssets || [];
        element.assetError = (this.isTrustMiningStage && asset.modified);
        element.assetErrorType = ((_a = asset.error) === null || _a === void 0 ? void 0 : _a.type) || null;
        element.incomingText = asset.incomingText;
        if (!this.isRedactionStage) {
            element.sourceText = asset.sourceText;
        }
        if (this.content.querySelector(`xfl-new-editor-asset[order='${asset.order}']`) !== null)
            return; // prevent duplicates
        const pivot = Array.from(this.content.querySelectorAll("xfl-new-editor-asset")).find(el => el.order > index);
        pivot ? this.content.insertBefore(element, pivot) : this.content.insertBefore(element, this.content.querySelector("xfl-new-editor-infinite-scroll-loader.forward"));
        this.modules.forEach(m => m.assetCreated(element, asset));
    }
    async fetchAssetsTerminology(assetIds) {
        try {
            const terms = (await getAssetsTerminology(this.assignmentId, assetIds));
            Object.keys(terms).forEach((key) => {
                Array.from(this.content.querySelectorAll("xfl-new-editor-asset"))
                    .filter(asset => asset.assetId == key)
                    .forEach((asset) => {
                    terms[key].forEach((term) => {
                        if (!asset.terms.some(sa => sa.id == term.id)) {
                            asset.terms.push(term);
                        }
                    });
                    asset.refresh();
                });
            });
        }
        catch (error) {
            HTTPLogger.error('System - Failed to get terminology', error);
        }
    }
    async fetchAssignment() {
        try {
            const response = await getAssignmentInfo(this.assignmentId);
            HTTPLogger.info(`System - Stage type - ${response.stage}`);
            return response;
        }
        catch (error) {
            this.setState({ error });
            HTTPLogger.error('System - Failed to get assignment info', error);
            if (error.response.status === 404) {
                toast.error(i18next.t('dashboard.projectTaken'));
            }
            if (error.response.status === 404 || error.response.status === 400) {
                const { history } = this.props;
                history.push('/');
            }
            return null;
        }
    }
    fetchAssets(offset, limit) {
        if (!this.section)
            return Promise.reject([]);
        return getAssignmentJobSectionAssets(this.assignmentId, this.section.id, offset, limit);
    }
    jumpBy(count) {
        const assets = Array.from(this.content.querySelectorAll("xfl-new-editor-asset"));
        const activeAssetIndex = assets.findIndex(a => a.active);
        if (activeAssetIndex >= 0) {
            this.activeAsset = assets[Math.min(Math.max(0, activeAssetIndex + count), assets.length - 1)];
        }
    }
    async jumpToNextUnfinishedAsset() {
        var _a;
        const { activeAsset } = this;
        const startAsset = (activeAsset || this.content.querySelector("xfl-new-editor-asset"));
        if (!startAsset || !this.section)
            return;
        if (!activeAsset && !startAsset.isFinished()) {
            this.activeAsset = startAsset;
            return;
        }
        let next = startAsset === null || startAsset === void 0 ? void 0 : startAsset.nextElementSibling;
        while (next != activeAsset) {
            if (next === null) {
                next = this.content.querySelector("xfl-new-editor-asset"); // back to first
                continue;
            }
            if (next instanceof InfiniteScrollLoader)
                break; // prevents getting loader as asset
            if (!next.isFinished()) {
                this.activeAsset = next;
                return;
            }
            next = next.nextElementSibling;
        }
        const nextAssetOrder = await getJobSectionNextUncompletedAssetOrder(this.assignmentId, this.section.id);
        if (nextAssetOrder == -1) {
            this.refreshEditorProgress(this.assignmentId);
            return; // prevents infi loop on special cases
        }
        (_a = this.scroll) === null || _a === void 0 ? void 0 : _a.moveTo(nextAssetOrder, this.removeAllAssets.bind(this));
        this.activeAsset = null;
    }
    async refreshEditorProgress(assignemntId) {
        var _a;
        if (this.isAssignmentProgressRefreshed)
            return;
        try {
            const progress = await refreshAssignmentProgress(assignemntId);
            this.contentEditionModule && this.contentEditionModule.updateProgress(progress, [(_a = this._activeSection) === null || _a === void 0 ? void 0 : _a.id]);
            this.isAssignmentProgressRefreshed = true;
        }
        catch (error) {
            HTTPLogger.error('System - Failed to refresh assignment progress', error);
        }
    }
    placeCursorInActiveAsset(end = false) {
        var _a, _b;
        if (!this.activeAsset) {
            return;
        }
        const range = document.createRange();
        range.selectNodeContents(this.activeAsset.contentDiv);
        range.collapse(!end);
        (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
        (_b = window.getSelection()) === null || _b === void 0 ? void 0 : _b.addRange(range);
    }
    updateActiveAsset() {
        const range = window.getSelection().getRangeAt(0);
        try { // Firefox problem workaround
            range.startContainer.parentNode;
        }
        catch (error) {
            return;
        }
        const asset = ancestorOfType(range.startContainer, "xfl-new-editor-asset");
        this.activeAsset = asset || this.activeAsset;
    }
    getAssetDuplicates(asset) {
        return Array.from(this.content.querySelectorAll("xfl-new-editor-asset"))
            .filter(a => a != asset && a.assetId == asset.assetId);
    }
    updateSelectionRange() {
        const range = firstSelectionRange();
        if (ancestorOfType(range.startContainer, "xfl-new-editor-asset")) {
            this.selectionRange = range;
        }
    }
    restoreSelectionRange() {
        var _a, _b;
        if (!this.selectionRange) {
            return;
        }
        const startNode = this.selectionRange.startContainer;
        (startNode instanceof HTMLElement ? startNode : startNode.parentElement).focus();
        (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
        (_b = window.getSelection()) === null || _b === void 0 ? void 0 : _b.addRange(this.selectionRange);
    }
    insertSubAsset(range, subAsset) {
        var _a;
        const subAssetElement = new SubAssetElement(subAsset, range.toString(), this);
        this.modules.forEach(m => m.subassetCreated(subAssetElement));
        range.deleteContents();
        range.insertNode(subAssetElement);
        const asset = ancestorOfType(subAssetElement, "xfl-new-editor-asset");
        const { subAssets } = asset;
        subAssets.push(subAsset);
        asset.subAssets = subAssets;
        (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
        this.commit(asset);
    }
    insertTerminology(range, term) {
        var _a, _b;
        const subAssetElement = new TerminologyElement(term, range.toString(), this, false, [term, ...((_a = this.activeAsset) === null || _a === void 0 ? void 0 : _a.terms) || []]);
        range.deleteContents();
        range.insertNode(subAssetElement);
        const asset = ancestorOfType(subAssetElement, "xfl-new-editor-asset");
        asset.terms.push(term);
        asset.refresh();
        (_b = window.getSelection()) === null || _b === void 0 ? void 0 : _b.removeAllRanges();
    }
    removeSubAsset(event, subAssetElement) {
        event.stopPropagation();
        event.preventDefault();
        const asset = ancestorOfType(subAssetElement, "xfl-new-editor-asset");
        subAssetElement.outerHTML = getSanitizedHTML(subAssetElement.text || '');
        asset.subAssets = asset.subAssets.filter(sa => sa.id != subAssetElement.subAssetId);
        asset && this.commit(asset);
    }
    removeFormatting(universalTag) {
        var _a;
        const newNode = document.createDocumentFragment();
        universalTag.getChildren().forEach((node) => {
            newNode.appendChild(node);
        });
        universalTag.replaceWith(newNode);
        (_a = universalTag.closest('xfl-new-editor-asset')) === null || _a === void 0 ? void 0 : _a.updateTagsState();
        this.activeAsset && this.commit(this.activeAsset);
    }
    async commit(asset) {
        var _a;
        this.clearCommitTimeout(asset);
        const tokenSubAssets = asset.subAssets
            .filter(({ type }) => ['ANONYMIZED', 'NO_TRANSLATION'].includes(type))
            .map(asset => ({ ...asset, subtypes: Array.from(asset.subtypes) })); // Must change Set to array
        const commit = {
            assetId: asset.assetId,
            content: asset.currentText,
            completed: asset.completed,
            subassets: tokenSubAssets
        };
        try {
            await commitAsset(this.assignmentId, commit);
            HTTPLogger.info('User - Commited asset changes');
            (_a = this.scroll) === null || _a === void 0 ? void 0 : _a.updateAssetsState(asset.assetId, asset.currentText, asset.completed);
        }
        catch (error) {
            if (error.response.status != 409)
                this.setState({ error });
            HTTPLogger.error('User - Failed to commit asset', error);
        }
    }
    clearCommitTimeout(asset) {
        this.commitTimeouts.has(asset.assetId) && window.clearTimeout(this.commitTimeouts.get(asset.assetId));
        this.commitTimeouts.delete(asset.assetId);
    }
    updateSectionProgress(sectionId, totalAssets, completedAssets, hasResultFile) {
        this.sectionBar.updateSection(sectionId, totalAssets, completedAssets, hasResultFile);
        this.sections = this.sections.map((section) => {
            if (section.id === sectionId) {
                return { ...section, totalAssets, completedAssets, hasResultFile: hasResultFile !== undefined ? hasResultFile : section.hasResultFile };
            }
            return section;
        });
    }
    get activeAsset() {
        return this.content.querySelector("xfl-new-editor-asset[active]");
    }
    set activeAsset(asset) {
        const { activeAsset } = this;
        if (activeAsset == asset) {
            return;
        }
        if (this.assetSourceWindow) {
            this.assetSourceWindow.open = false;
        }
        if (activeAsset) {
            activeAsset.showSource = false;
            activeAsset.active = false;
        }
        if (asset) {
            asset.active = true;
            asset.contentDiv.focus();
            if (asset.sections.length === 0) {
                asset.fetchExtraData();
            }
            this.observer.observe(asset.contentDiv, { subtree: true, characterData: true });
            window.requestAnimationFrame(() => asset.scrollIntoView({ block: "nearest", behavior: "smooth" }));
        }
    }
    get stage() {
        return this.domNode.dataset.stage;
    }
    set stage(stage) {
        this.domNode.dataset.stage = stage;
    }
    static canCreateSubasset(type) {
        function charBefore(range) {
            var _a, _b, _c;
            return ((_a = range.startContainer.textContent) === null || _a === void 0 ? void 0 : _a.charAt(range.startOffset - 1))
                || ((_c = (_b = range.startContainer.previousSibling) === null || _b === void 0 ? void 0 : _b.textContent) === null || _c === void 0 ? void 0 : _c.slice(-1)) || null;
        }
        function charAfter(range) {
            var _a, _b;
            if (range.commonAncestorContainer == range.endContainer && !(range.commonAncestorContainer instanceof Text)) {
                return ''; // prevent selection outside text
            }
            return ((_a = range.endContainer.textContent) === null || _a === void 0 ? void 0 : _a.charAt(range.endOffset))
                || ((_b = range.endContainer.nextSibling) === null || _b === void 0 ? void 0 : _b.textContent[0]) || null;
        }
        function containsTag(selection, type, text) {
            const universalTags = Array.from(startAsset.contentDiv.querySelectorAll("universal-tag"));
            const subAssets = Array.from(startAsset.contentDiv.querySelectorAll("xfl-new-editor-subasset"));
            const terms = Array.from(startAsset.contentDiv.querySelectorAll("xfl-new-editor-terminology"));
            const selectedNodes = Array.from((range === null || range === void 0 ? void 0 : range.cloneContents().childNodes) || []);
            const compareNodes = (nodes1, nodes2) => {
                if (nodes1.length == 0)
                    return true;
                return !!nodes1.filter(node => nodes2.find(node2 => node.id === node2.id && node.innerText === node2.innerText)).length;
            };
            const minTermLength = isScriptoContinua(startAsset.editor.targetLanguage) ? 1 : MIN_TERM_LENGTH;
            switch (type) {
                case 'FORMATTING':
                    return text.length >= MIN_FORMATTING_LENGTH
                        && compareNodes(selectedNodes.filter(node => node instanceof UniversalTagElement), universalTags)
                        && compareNodes(selectedNodes.filter(node => node instanceof SubAssetElement), subAssets)
                        && (!subAssets.some(subAsset => ['ANONYMIZED', 'NO_TRANSLATION'].includes(subAsset.type) && selection.containsNode(subAsset, true)));
                case 'ANONYMIZED':
                case 'NO_TRANSLATION':
                    return text.length >= minTermLength && text.length <= MAX_TERM_LENGTH
                        && !subAssets
                            .some(subAsset => selection.containsNode(subAsset, true))
                        && compareNodes(selectedNodes.filter(node => node instanceof UniversalTagElement), universalTags);
                case 'TERM':
                case 'FORBIDDEN':
                    return text.length >= minTermLength && text.length <= MAX_TERM_LENGTH
                        && !terms
                            .some(term => selection.containsNode(term, true))
                        && !selectedNodes.filter(node => node instanceof UniversalTagElement).length;
                default:
                    return false;
            }
        }
        const selection = window.getSelection();
        const range = selection.rangeCount == 1 ? selection.getRangeAt(0) : null;
        if (!selection || !range || !(range === null || range === void 0 ? void 0 : range.toString)) {
            return false;
        }
        const startAsset = ancestorOfType(range.startContainer, "xfl-new-editor-asset");
        const endAsset = ancestorOfType(range.endContainer, "xfl-new-editor-asset");
        const selectedText = range.toString();
        return startAsset !== null && startAsset == endAsset
            && startAsset.contentDiv.contains(range.startContainer)
            && startAsset.contentDiv.contains(range.endContainer)
            && !!type && containsTag(selection, type, selectedText)
            && selectedText.trim() == selectedText
            && (!(!!charBefore(range) && charBefore(range).length > 0 && isAlphanumeric(charBefore(range) || "")) || isScriptoContinua(startAsset.editor.targetLanguage))
            && (!(!!charAfter(range) && charAfter(range).length > 0 && isAlphanumeric(charAfter(range) || "")) || isScriptoContinua(startAsset.editor.targetLanguage));
    }
    onKeyDown(event) {
        var _a;
        switch (event.key) {
            case "ArrowUp":
            case "ArrowDown":
                if ((event.ctrlKey || event.metaKey)) {
                    if (this.activeAsset) {
                        this.jumpBy(event.key == "ArrowDown" ? 1 : -1);
                    }
                    else {
                        const assets = Array.from(this.content.querySelectorAll("xfl-new-editor-asset"));
                        this.activeAsset = assets[event.key == "ArrowDown" ? 0 : assets.length - 1];
                    }
                    this.placeCursorInActiveAsset();
                    consumeEvent(event);
                }
                break;
            case "Tab":
                consumeEvent(event);
                break;
            case "s":
            case "S":
                if (event.ctrlKey || event.metaKey) {
                    if (this.activeAsset) {
                        HTTPLogger.info(`User - ${!this.activeAsset.showSource ? 'Opened' : 'Closed'} source text`);
                        this.activeAsset.showSource = !this.activeAsset.showSource;
                    }
                    consumeEvent(event);
                }
                break;
            case "Escape":
                (_a = this.assetSourceWindow) === null || _a === void 0 ? void 0 : _a.closeWindow();
                break;
        }
        window.setTimeout(() => this.updateActiveAsset(), 0);
        this.modules.forEach(m => m.keyDown(event));
    }
    onKeyDownComplete(event) {
        this.modules.forEach(m => m.keyDownComplete(event));
    }
    onKeyPress(event) {
        this.modules.forEach(m => m.keyPress(event));
    }
    onMouseUp() {
        this.updateSelectionRange();
    }
    onInput() {
        this.modules.forEach(m => m.input());
    }
    onClipboardEvent(event) {
        this.modules.forEach(m => m.clipboardEvent(event));
    }
    onSelectionChange() {
        this.selectionTools.trigger(ReactDOM.findDOMNode(this));
        this.modules.forEach(m => m.selectionChanged());
    }
    onAssetChange(asset) {
        this.modules.forEach(m => m.assetChanged(asset));
    }
    set disabled(value) {
        this.disabledScreen.classList.toggle('disabled', value);
    }
    get disabled() {
        return this.disabledScreen.classList.contains('disabled');
    }
    set section(newSection) {
        this._activeSection = newSection;
        if (!newSection)
            return;
        this.setSectionScroll(newSection.id, newSection.totalAssets);
        this.modules.forEach(module => module === null || module === void 0 ? void 0 : module.clearSearchPhrase());
        if (this.contentEditionModule) {
            const sectionProgress = calculateProgress(newSection.totalAssets, newSection.completedAssets);
            this.contentEditionModule.sectionProgressBar.value = sectionProgress;
            this.contentEditionModule.totalSectionAssets = newSection.totalAssets;
            this.contentEditionModule.remainingSectionAssets = newSection.totalAssets - newSection.completedAssets;
        }
        if (this.contentEditionModule && this.editable) {
            this.contentEditionModule.assetEdition = !(this.isFinalRedactionStage && newSection.hasResultFile);
        }
        if (['REDACTION', 'FINAL_REDACTION'].includes(this.stage)) {
            this.unloadModule('SourceFilePreviewModule');
            this.unloadModule('SourceFileDownloadModule');
            this.unloadModule('SnapshotModule');
            if (this.editable && this.isFinalRedactionStage) {
                this.loadModule(new SnapshotModule(this));
            }
            if (this.editable) {
                this.loadModule(new SourceFileDownloadModule(this));
            }
            if (newSection.hasSourceFile) {
                this.loadModule(new SourceFilePreviewModule(this));
            }
        }
    }
    get section() {
        return this._activeSection;
    }
    reloadAssetsAfterFileUpload() {
        var _a;
        this.reloadAssets();
        const assetsUpdatedModal = new UploadAfterDocumentModal();
        (_a = this.domNode) === null || _a === void 0 ? void 0 : _a.appendChild(assetsUpdatedModal);
        assetsUpdatedModal.header = i18next.t('editor.uploadDocumentModal.updatedAssetsHeader');
        assetsUpdatedModal.description = i18next.t('editor.uploadDocumentModal.updatedAssetsDescription');
        assetsUpdatedModal.open = true;
        assetsUpdatedModal.actions = [{ text: i18next.t('editor.uploadDocumentModal.OkGotIt'), classList: ['primary'], callback: () => {
                    assetsUpdatedModal.open = false;
                    assetsUpdatedModal.unload();
                } }];
    }
    reloadAssets() {
        var _a, _b, _c, _d;
        this.section = this.section;
        (_a = this.contentEditionModule) === null || _a === void 0 ? void 0 : _a.updateProgress(undefined, [(_b = this.section) === null || _b === void 0 ? void 0 : _b.id]);
        (_d = (_c = this.domNode) === null || _c === void 0 ? void 0 : _c.querySelector("xfl-new-editor-exfluency-loader")) === null || _d === void 0 ? void 0 : _d.remove();
    }
    fileUploadFinalRedaction() {
        var _a, _b, _c;
        if (!this.section)
            return;
        const { id, totalAssets, completedAssets } = this.section;
        this.updateSectionProgress(id, totalAssets, completedAssets, true);
        (_b = (_a = this.domNode) === null || _a === void 0 ? void 0 : _a.querySelector("xfl-new-editor-exfluency-loader")) === null || _b === void 0 ? void 0 : _b.remove();
        const assetsUpdatedModal = new UploadAfterDocumentModal();
        (_c = this.domNode) === null || _c === void 0 ? void 0 : _c.appendChild(assetsUpdatedModal);
        assetsUpdatedModal.header = i18next.t('editor.uploadDocumentModal.uploadedFinalRedactionHeader');
        assetsUpdatedModal.description = i18next.t('editor.uploadDocumentModal.uploadedFinalRedactionDescription');
        assetsUpdatedModal.open = true;
        assetsUpdatedModal.actions = [{ text: i18next.t('editor.uploadDocumentModal.OkGotIt'), classList: ['primary'], callback: () => {
                    if (this.contentEditionModule && this.editable) {
                        this.contentEditionModule.assetEdition = false;
                        this.contentEditionModule.disableAssetsEdition();
                    }
                    assetsUpdatedModal.open = false;
                    assetsUpdatedModal.unload();
                } }];
    }
    exitEditor() {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.history.push("/");
    }
    render() {
        var _a, _b, _c, _d, _e;
        const { error: errorWindowMessage } = this.state;
        if (((_b = (_a = this.state.error) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.status) == 403)
            return React.createElement(PermissionErrorModal, { onClose: () => { window.location.href = ""; }, message: (_e = (_d = (_c = this.state.error) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message });
        return errorWindowMessage !== null
            ? React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() })
            : React.createElement("div", { className: "newEditorPanel" });
    }
}
export default withTranslation('common')(EditorNextGen);
