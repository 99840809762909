import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Popup, Button, Modal } from 'semantic-ui-react';
import { Styled } from 'StyledComponents';
import { toast } from 'react-toastify';
import { reopen, shareAssets } from 'api-operations/requester/job';
import { useRequesterJob } from './Dashboard';
import { useLogger } from 'logger/Logger';
import { useTokenization } from 'providers/TokenizationProvider';
const ActionButtons = ({ job, setReopenStageId }) => {
    const [sharingAsset, setSharingAsset] = useState(false);
    const [isReopenModalOpen, setIsReopenModalOpen] = useState(false);
    const [isAssetsShared, setIsAssetsShared] = useState(job.visibility.openCommunity || false);
    const { isTokenizationEnabled } = useTokenization();
    const { t } = useTranslation('common');
    const history = useHistory();
    const { fetchJobs } = useRequesterJob();
    const { logger } = useLogger();
    const shareAssetsFunc = async () => {
        setSharingAsset(true);
        try {
            await shareAssets(job.id);
            setIsAssetsShared(true);
            logger.info('User - Successfully share assets');
        }
        catch (error) {
            logger.error('User - Failed to share assets', error);
            toast.error(t('notification.error'));
        }
        setSharingAsset(false);
    };
    const assetsSharingEnabled = () => {
        return (job.status === 'FINISHED' || job.status === 'CANCELLED') && job.stages.find(({ stageType, stageStatus }) => stageType === 'ENHANCEMENT' && stageStatus === 'DONE');
    };
    /**
     * @Deprecated (TBR 3.36) Opening the editor is handled by JobPreviewButton
     */
    const openEditor = () => {
        const finishedAssignments = job.stages
            .flatMap(stage => stage.assignments)
            .filter(assignment => assignment.status === 'FINISHED' || assignment.status === 'INTERRUPTED');
        if (finishedAssignments.length == 0)
            return;
        logger.info('User - Clicked editor');
        const lastFinishedAssignmentId = finishedAssignments[finishedAssignments.length - 1].id;
        history.push({
            pathname: `/editor/${lastFinishedAssignmentId}`,
            state: { id: lastFinishedAssignmentId }
        });
    };
    const createReopenStage = async () => {
        try {
            const response = await reopen(job.id);
            setReopenStageId(response);
            logger.info('User - Reopened job');
            await fetchJobs();
        }
        catch (error) {
            logger.error('User - Failed to reopen job');
            toast.error(t('notification.error'));
        }
    };
    const handleOpenReopenJobModal = () => {
        setIsReopenModalOpen(true);
        logger.info('User - Opened reopen job modal');
    };
    const isCorrectionAllowed = () => {
        return job.status !== 'CANCELLED' && job.stages.find(({ stageType }) => stageType == 'ENHANCEMENT');
    };
    return React.createElement(React.Fragment, null,
        assetsSharingEnabled()
            && React.createElement(Popup, { basic: true, position: "top center", hoverable: true, trigger: React.createElement("div", null,
                    React.createElement(Button, { icon: "share alternate", "data-testid": 'share_btn', disabled: isAssetsShared })) }, isAssetsShared
                ? React.createElement("p", null, t('dashboard.assetsAlreadyShared'))
                : React.createElement(Styled.VerticalPopupContent, null,
                    React.createElement("h4", null,
                        t('dashboard.wantToShare'),
                        " ",
                        isTokenizationEnabled ? t('dashboard.wantToShareCoin') : '.'),
                    React.createElement(Button, { color: "blue", "data-testid": `${job.id}_share_btn`, content: isTokenizationEnabled ? t('dashboard.shareAndEarn') : t('dashboard.share'), fluid: true, loading: sharingAsset, onClick: () => shareAssetsFunc() }))),
        isCorrectionAllowed() && React.createElement(Popup, { basic: true, position: "top center", content: t('dashboard.reopen'), trigger: React.createElement(Button, { icon: "redo", color: "orange", onClick: () => handleOpenReopenJobModal(), "data-testid": 'reopen_btn' }) }),
        React.createElement(Modal, { open: isReopenModalOpen },
            React.createElement(Modal.Content, null, t('dashboard.reopenProjectModal', { name: job.name })),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { positive: true, content: t('common.yes'), onClick: () => { setIsReopenModalOpen(false), createReopenStage(); } }),
                React.createElement(Button, { negative: true, content: t('common.cancel'), onClick: () => setIsReopenModalOpen(false) }))));
};
export default ActionButtons;
