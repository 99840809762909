import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Card, Popup, Grid, Search, Button, Image } from 'semantic-ui-react';
import PublicProfile from 'helpers/components/PublicProfile';
import { getImagePathByRole } from 'helpers/utils';
import { addUserToGatedCommunity, changeUserMembershipStatusInGatedCommunity, getPendingCommunityMembers, searchCandidatesToGc } from 'api-operations/community/admin';
import Avatar from 'helpers/components/Avatar';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
const RolesDiv = styled.div `
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 3.5rem;
`;
const H2 = styled.h2 `
  text-align: center
`;
const DangerH4 = styled.h4 `
  text-align: center;
  color: red
`;
const NothingToShow = styled.div `
  font-size: 2rem;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
`;
const AddMember = () => {
    const [searchData, setSearchData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [communityMembers, setCommunityMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation('common');
    const params = useParams();
    useEffect(() => {
        fetchData();
    }, []);
    const handleResultSelect = (__, { result }) => setSearchValue(result.login);
    const search = useCallback(_.debounce(async (value) => {
        try {
            const res = await searchCandidatesToGc(params.id, value);
            setSearchData(res);
        }
        catch (error) {
            //
        }
        setSearchLoading(false);
    }, 1000), []);
    const handleSearchChange = (__, { value }) => {
        setSearchLoading(true);
        setSearchValue(value || '');
        if (!value) {
            setSearchValue('');
            setSearchData([]);
            setSearchLoading(false);
        }
        else {
            search(value);
        }
    };
    const fetchData = async () => {
        if (isError) {
            setIsError(false);
            setIsLoading(true);
        }
        try {
            const response = await getPendingCommunityMembers(params.id);
            setCommunityMembers(response);
        }
        catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    };
    const addNewUser = async () => {
        const userToAdd = [{
                userName: searchValue,
                profile: 'GATED_COMMUNITY_MEMBER'
            }];
        try {
            await addUserToGatedCommunity(params.id, userToAdd);
            toast.success(t('notification.userAdded'));
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
        setSearchValue('');
    };
    const handlePendingMember = async (userId, status) => {
        try {
            await changeUserMembershipStatusInGatedCommunity(params.id, userId, status);
            setCommunityMembers(prevState => prevState.filter(user => user.userId !== userId));
            if (status === 'REJECTED') {
                toast.success(t('notification.userRemoved'));
            }
            else {
                toast.success(t('notification.userAdded'));
            }
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const resultRenderer = ({ login, roles, communities }) => (React.createElement(Card, { centered: true, fluid: true, color: communities.length > 0 && communities[0] !== null ? 'red' : undefined },
        React.createElement(Card.Content, null,
            React.createElement(Card.Header, null,
                login,
                React.createElement(PublicProfile, { userName: login })),
            React.createElement(RolesDiv, null, roles.filter((role) => ['ROLE_REDACTOR', 'ROLE_ENHANCER', 'ROLE_TRUST_MINER', 'ROLE_BUYER'].includes(role))
                .map((role) => React.createElement(Popup, { content: t(`userRoles.${role}`), trigger: React.createElement(Image, { src: getImagePathByRole(role) }) }))),
            communities.length > 0 && React.createElement(DangerH4, null,
                " ",
                t(`gatedCommunities.userInOtherGcError`))),
        communities.length > 0 && React.createElement(Card.Content, { extra: true },
            React.createElement("span", null, communities.join(', ')))));
    if (isLoading)
        return React.createElement(ExfluencyLoader, null);
    return React.createElement(React.Fragment, null,
        React.createElement(H2, null, t(`gatedCommunities.addNewMember`)),
        React.createElement(Grid, { columns: 3, divided: true },
            React.createElement(Grid.Row, null,
                React.createElement(Grid.Column, null,
                    React.createElement(Search, { fluid: true, value: searchValue, results: searchData, onSearchChange: handleSearchChange, onResultSelect: handleResultSelect, loading: searchLoading, resultRenderer: resultRenderer })),
                React.createElement(Grid.Column, null,
                    React.createElement(Button, { primary: true, disabled: searchValue === ''
                            || searchData.length === 0, onClick: addNewUser }, t(`gatedCommunities.addUser`))))),
        React.createElement(H2, null, t(`gatedCommunities.pendingMembers`)),
        communityMembers.length > 0 ? React.createElement(Card.Group, { itemsPerRow: 4 }, communityMembers.map(({ login, name, surname, otherCommunities, roles, userId }) => React.createElement(Card, { key: userId },
            React.createElement(Card.Content, null,
                React.createElement(Avatar, { name: login, inlineAvatar: true }),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement(Card.Header, null,
                    login,
                    React.createElement(PublicProfile, { userName: login })),
                React.createElement(Card.Meta, null,
                    name,
                    " ",
                    surname),
                React.createElement(Card.Description, null,
                    React.createElement(RolesDiv, null, roles.map((role) => React.createElement(Popup, { content: t(`userRoles.${role}`), trigger: React.createElement(Image, { src: getImagePathByRole(role) }) }))),
                    otherCommunities.length > 0 && React.createElement(DangerH4, null,
                        " ",
                        t(`gatedCommunities.userInOtherGcError`)),
                    otherCommunities.length > 0 && React.createElement(Card.Content, { extra: true },
                        React.createElement("span", null, otherCommunities.join(', '))))),
            React.createElement(Card.Content, { extra: true },
                React.createElement(Button.Group, { fluid: true },
                    React.createElement(Button, { basic: true, color: 'green', onClick: () => handlePendingMember(userId, 'MEMBER') }, t('common.approve')),
                    React.createElement(Button, { basic: true, color: 'red', onClick: () => handlePendingMember(userId, 'REJECTED') }, t('common.decline')))))))
            : React.createElement(NothingToShow, null, t('gatedCommunities.noPendingUsers')));
};
export default AddMember;
